<script setup lang="ts">
</script>

<template>

  <div class="
    flex flex-col flex-direction
    justify-start items-center
    bg-black
    h-screen px-10 pt-20 size-full">

    <h1
      style="-webkit-text-stroke: 2px black;"
      class="
        text-center capitalize font-display text-5xl
        text-special-green leading-10
        custom-shadow-large mb-4 ">
      Game Running
    </h1>

    <p class="paragraph custom-shadow-small mt-8">
      Money shot is active in another tab.
    </p>

    <p class="paragraph custom-shadow-small mt-4">
      You can't other sessions until you close this tab.
    </p>

    <FollowUs class="absolute bottom-10" />

  </div>

</template>

<style scoped>
.paragraph {
  @apply capitalize text-white text-xl text-center;
}
</style>
