import { default as earn11ApSWO1WYMeta } from "/tmp/seed/source/modules/www/pages/earn.vue?macro=true";
import { default as indexygX6bhACOOMeta } from "/tmp/seed/source/modules/www/pages/index.vue?macro=true";
import { default as invite8nAYMx2UW3Meta } from "/tmp/seed/source/modules/www/pages/invite.vue?macro=true";
import { default as levelupBjyHcegjSgMeta } from "/tmp/seed/source/modules/www/pages/levelup.vue?macro=true";
import { default as perksexFlor82FdMeta } from "/tmp/seed/source/modules/www/pages/perks.vue?macro=true";
import { default as statsLe7zZQxUndMeta } from "/tmp/seed/source/modules/www/pages/stats.vue?macro=true";
export default [
  {
    name: "earn",
    path: "/earn",
    component: () => import("/tmp/seed/source/modules/www/pages/earn.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/tmp/seed/source/modules/www/pages/index.vue")
  },
  {
    name: "invite",
    path: "/invite",
    component: () => import("/tmp/seed/source/modules/www/pages/invite.vue")
  },
  {
    name: "levelup",
    path: "/levelup",
    component: () => import("/tmp/seed/source/modules/www/pages/levelup.vue")
  },
  {
    name: "perks",
    path: "/perks",
    component: () => import("/tmp/seed/source/modules/www/pages/perks.vue")
  },
  {
    name: "stats",
    path: "/stats",
    component: () => import("/tmp/seed/source/modules/www/pages/stats.vue")
  }
]