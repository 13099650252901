import fetchRetry from "fetch-retry";
import type { PerkId } from "@moneyshot/core/perk";
import type { BalanceDelta, ReferralStats, TapDuration, UnixTimestamp, User } from "@moneyshot/db/tables";
import type { Task, TaskId } from "@moneyshot/core/task";

const wrappedFetch = fetchRetry(fetch);

/**
 * Shortens the syntax for fetching data from the API.
 * @param endpoint
 * @param options
 * @returns
 */
const apiFetch = async (endpoint: string, options: RequestInit = {}) => {
  const config = useRuntimeConfig();
  return wrappedFetch(`${config.public.apiUrl}/${endpoint}`, {
    retryOn: [503],
    retries: 3,
    retryDelay: 0,
    ...options,
  });
};

export class Api {
  /**
   * Fetches the user's data from the server.
   * @returns
   */
  static async getReferralStats() {
    const store = useStore();
    if (!store.user) {
      console.warn("Cant update balance, no user authenticated!");
      return;
    }

    const user = store.user;

    return apiFetch(`users/${user.id}/referrals`).then(async (res) => {
      const json = (await res.json()) as ApiResponse<ReferralStats>;
      return json.data;
    });
  }

  /**
   * Fetches the user's friends data from the server.
   * @returns
   */
  static async getFriends() {
    const store = useStore();
    if (!store.user) {
      console.warn("Cant update balance, no user authenticated!");
      return;
    }

    const user = store.user;

    return apiFetch(`users/${user.id}/friends`).then(async (res) => {
      const json = (await res.json()) as ApiResponse<User[]>;
      return json.data;
    });
  }

  /**
   * Fetches the user rank from the server.
   * @returns
   */
  static async getPlayerRank(userId: number) {
    return apiFetch(`users/${userId}/rank`).then(async (res) => {
      const json = (await res.json()) as ApiResponse<number>;
      return json.data;
    });
  }

  /**
   * Fetches the top users data from the server.
   * @returns
   */
  static async getLeaderboard(limit: number = 10) {
    return apiFetch(`stats/leaderboard?limit=${limit}`).then(async (res) => {
      const json = (await res.json()) as ApiResponse<User[]>;
      return json.data;
    });
  }

  /**
   * Fetches the user's data from the server.
   * @param userId
   * @returns
   */
  static async getUser(userId: number) {
    return apiFetch(`users/${userId}?notifyActivity=true`).then(async (res) => {
      const json = (await res.json()) as ApiResponse<User>;
      return json.data;
    });
  }

  /**
   * Purchases a perk for the user.
   * @param perkId
   * @returns
   */
  static async purchasePerk<T extends PerkId>(perkId: T) {
    const store = useStore();
    const user = store.user;

    return apiFetch(`/upgrade/perk`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        clientBalance: user.balance,
        perkId,
        userId: user.id,
      }),
    }).then(async (res) => {
      const json = (await res.json()) as ApiResponse<User | null>;
      return json.data;
    });
  }

  static startTask(taskId: TaskId) {
    const store = useStore();
    const user = store.user;

    return apiFetch(`/tasks/${taskId}/start`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: user.id,
      }),
    }).then(async (res) => {
      const json = (await res.json()) as ApiResponse<Task>;
      return json.data!;
    });
  }

  static completeTask(taskId: TaskId) {
    const store = useStore();
    const user = store.user;

    return apiFetch(`/tasks/${taskId}/complete`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        taskId,
        userId: user.id,
      }),
    }).then(async (res) => {
      const json = (await res.json()) as ApiResponse<Task | null>;
      return json.data!;
    });
  }

  /**
   * Upgrades the user's SES level.
   * @returns
   */
  static async upgradeSesLevel() {
    const store = useStore();

    return apiFetch(`/upgrade/sesLevel`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        clientBalance: store.user.balance,
        userId: store.user.id,
      }),
    }).then(async (res) => {
      const json = (await res.json()) as ApiResponse<User | null>;
      return json.data;
    });
  }

  /**
   * Syncs the user's balance with the server.
   * @param updates
   * @returns
   */
  static async syncBalance(updates: [UnixTimestamp, BalanceDelta, TapDuration][]) {

    const store = useStore();
    const user  = store.user;

    return apiFetch(`/sync/balance`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: user.id,
        updates,
      }),
    });
  }

  /**
   * Syncs the user's pile with the server.
   * @param catchUpDuration
   * @returns
   */
  static async syncPile(catchUpDuration: number = 1) {

    const store = useStore();
    const user  = store.user;

    return apiFetch(`/sync/pile`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userId: user.id,
        catchUpDuration,
      }),
    });
  }

}
