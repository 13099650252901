import { getPerk } from "@moneyshot/core/perk";
import { getIncomePerTap } from "@moneyshot/core/ses";
import { UndefinedUserError, UnexpectedEmptyPileSizeError } from "@moneyshot/globals/errors";
import { SesLevel } from "@moneyshot/globals/vars";
import _ from "lodash";
import * as Sentry from '@sentry/vue';
import dayjs from "dayjs";


/**
 * Return the path to the throwable image based on the level.
 * @param level
 * @returns
 */
export const getThrowableImagePath = (level: SesLevel) => {
  const name = {
    [SesLevel.HOMELESS]: 'can',
    [SesLevel.JANITOR]: 'shoe',
    [SesLevel.NEWSPAPER_DELIVERY]: 'newspaper',
    [SesLevel.BURGER_FLIPPER]: 'steak',
    [SesLevel.SHELF_FILLER]: 'sock',
    [SesLevel.PLUMBER]: 'toilet-broom',
    [SesLevel.GARDENER]: 'seeds',
    [SesLevel.FISHER]: 'fish',
    [SesLevel.EXORCIST]: 'crucifix',
    [SesLevel.CASHIER]: 'banana',
    [SesLevel.FARMER]: 'fertilizer',
    [SesLevel.BOUNCER]: 'TODO:',
    [SesLevel.CONSTRUCTION_WORKER]: 'brick',
    [SesLevel.TRUCK_DRIVER]: 'cigarette',
    [SesLevel.BABY_SITTER]: 'teddy-bear',
    [SesLevel.WAITER]: 'plate',
    [SesLevel.HOUSEKEEPER]: 'pillow',
    [SesLevel.PIZZA_MAKER]: 'pizza',
    [SesLevel.DRUG_DEALER]: 'drug-package',
    [SesLevel.PAINTER]: 'paint-brush',
    [SesLevel.PIRATE]: 'canon-ball',
    [SesLevel.RECEPTIONIST]: 'paper-plane',
    [SesLevel.ZOMBIE_HUNTER]: 'knife',
    [SesLevel.NURSE]: 'pill',
    [SesLevel.PORN_PRODUCER]: 'dildo',
    [SesLevel.FACTORY_WORKER]: 'toolbox',
    [SesLevel.MACHINIST]: 'manual',
    [SesLevel.PILOT]: 'keys',
    [SesLevel.DEVELOPER]: 'keyboard',
    [SesLevel.LAWYER]: 'papers',
    [SesLevel.SURGEON]: 'heart',
    [SesLevel.GAMBLER]: 'token',
    [SesLevel.SCIENTIST]: 'jar',
    [SesLevel.CORPORATE_CONSULTANT]: 'postit',
    [SesLevel.BUSINESS_EXECUTIVE]: 'pencil',
    [SesLevel.NBA_PLAYER]: 'basketball',
    [SesLevel.BANKER]: 'dollars',
    [SesLevel.CEO]: 'fired-notice',
    [SesLevel.YACHT_OWNER]: 'sponge',
    [SesLevel.P_DIDDY]: 'lube',
  }[level];

  return `/images/throwables/${name}.png`;
};


const DEBOUNCE_DELAY   = 500;
const defaultImageSize = { w: 1000, h: 1000 };


const levelsMetadata: Partial<Record<SesLevel, {
  hit: { x: number, y: number }[],
  miss:  { x: number, y: number }[],
  arcHeight: number,
  distance:  number,
  rto: { x: [number, number], y: [number, number] },
}>> = {

  [SesLevel.HOMELESS]: {
    hit: [{ x: 500, y: 465 }],
    miss: [
      { x: 510, y: 615 },
      { x: 400, y: 580 },
      { x: 580, y: 580 },
    ],
    arcHeight: 200,
    distance:  16,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.JANITOR]: {
    hit: [{ x: 480, y: 470 }],
    miss: [
      { x: 580, y: 620 },
      { x: 330, y: 610 },
    ],
    arcHeight: 200,
    distance:  13,
    rto: { x: [10, 20], y: [0, 0] },
  },

  [SesLevel.NEWSPAPER_DELIVERY]: {
    hit: [{ x: 527, y: 585 }],
    miss: [
      { x: 635, y: 630 },
      { x: 403, y: 666 },
    ],
    arcHeight: 150,
    distance:  18,
    rto: { x: [10, 20], y: [0, 0] },
  },

  [SesLevel.BURGER_FLIPPER]: {
    hit: [{ x: 470, y: 455 }],
    miss: [
      { x: 520, y: 600 },
      { x: 370, y: 620 },
    ],
    arcHeight: 150,
    distance:  20,
    rto: { x: [30, 30], y: [0, 0] },
  },

  [SesLevel.SHELF_FILLER]: {
    hit: [{ x: 485, y: 530 }],
    miss: [
      { x: 350, y: 600 },
      { x: 620, y: 680 },
    ],
    arcHeight: 150,
    distance:  13,
    rto: { x: [100, 100], y: [0, 0] },
  },

  [SesLevel.PLUMBER]: {
    hit: [{ x: 495, y: 455 }],
    miss: [
      { x: 588, y: 557 },
      { x: 426, y: 595 },
    ],
    arcHeight: 150,
    distance:  16,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.GARDENER]: {
    hit: [
      { x: 385, y: 629 },
      { x: 602, y: 582 },
      { x: 443, y: 562 },
    ],
    miss: [
      { x: 300, y: 522 },
      { x: 644, y: 488 },
    ],
    arcHeight: 150,
    distance:  16,
    rto: { x: [10, 20], y: [0, 0] },
  },

  [SesLevel.FISHER]: {
    hit: [
      { x: 494, y: 340 }
    ],
    miss: [
      { x: 565, y: 551 },
      { x: 585, y: 147 },
    ],
    arcHeight: 200,
    distance:  13,
    rto: { x: [10, 20], y: [0, 0] },
  },

  [SesLevel.EXORCIST]: {
    hit: [{ x: 488, y: 463 }],
    miss: [
      { x: 595, y: 672 },
      { x: 307, y: 349 },
    ],
    arcHeight: 200,
    distance:  13,
    rto: { x: [10, 20], y: [0, 0] },
  },

  [SesLevel.CASHIER]: {
    hit: [{ x: 510, y: 450 }],
    miss: [
      { x: 610, y: 575 },
      { x: 400, y: 590 },
    ],
    arcHeight: 150,
    distance:  16,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.FARMER]: {
    hit: [{ x: 476, y: 415 }],
    miss: [
      { x: 707, y: 500 },
      { x: 309, y: 559 }
    ],
    arcHeight: 150,
    distance:  15,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.BOUNCER]: {
    hit: [{ x: 475, y: 510 }],
    miss: [
      { x: 380, y: 640 },
      { x: 580, y: 620 },
    ],
    arcHeight: 150,
    distance:  16,
    rto: { x: [5, 5], y: [0, 0] },
  },

  [SesLevel.CONSTRUCTION_WORKER]: {
    hit: [{ x: 510, y: 485 }],
    miss: [
      { x: 415, y: 620 },
      { x: 650, y: 650 },
    ],
    arcHeight: 150,
    distance:  16,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.TRUCK_DRIVER]: {
    hit: [{ x: 500, y: 480 }],
    miss: [
      { x: 651, y: 475 },
      { x: 383, y: 700 },
    ],
    arcHeight: 150,
    distance:  16,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.BABY_SITTER]: {
    hit: [{ x: 477, y: 573 }],
    miss: [
      { x: 600, y: 654 },
      { x: 327, y: 637 },
    ],
    arcHeight: 150,
    distance:  10,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.WAITER]: {
    hit: [
      { x: 490, y: 528 },
      { x: 314, y: 546 },
      { x: 683, y: 546 },
    ],
    miss: [
      { x: 516, y: 636 },
      { x: 333, y: 694 },
    ],
    arcHeight: 150,
    distance:  16,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.HOUSEKEEPER]: {
    hit: [{ x: 530, y: 540 }],
    miss: [
      { x: 605, y: 445 },
      { x: 425, y: 635 },
    ],
    arcHeight: 150,
    distance:  15,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.PIZZA_MAKER]: {
    hit: [{ x: 494, y: 435 }],
    miss: [{ x: 438, y: 642 }],
    arcHeight: 150,
    distance:  10,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.DRUG_DEALER]: {
    hit: [{ x: 521, y: 459 }],
    miss: [{ x: 335, y: 676 }],
    arcHeight: 150,
    distance:  10,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.PAINTER]: {
    hit: [{ x: 500, y: 458 }],
    miss: [{ x: 365, y: 722 }],
    arcHeight: 150,
    distance:  20,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.PIRATE]: {
    hit: [{ x: 528, y: 534 }],
    miss: [
      { x: 428, y: 632 },
      { x: 645, y: 608 },
    ],
    arcHeight: 150,
    distance:  22,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.RECEPTIONIST]: {
    hit: [{ x: 447, y: 500 }],
    miss: [
      { x: 570, y: 460 },
      { x: 510, y: 590 },
    ],
    arcHeight: 150,
    distance:  20,
    rto: { x: [5, 5], y: [0, 0] },
  },

  [SesLevel.ZOMBIE_HUNTER]: {
    hit: [{ x: 500, y: 418 }],
    miss: [{ x: 607, y: 487 }],
    arcHeight: 150,
    distance:  10,
    rto: { x: [5, 5], y: [0, 0] },
  },

  [SesLevel.NURSE]: {
    hit: [{ x: 640, y: 475 }],
    miss: [
      { x: 450, y: 520 },
      { x: 380, y: 610 },
    ],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.PORN_PRODUCER]: {
    hit: [{ x: 474, y: 475 }],
    miss: [{ x: 615, y: 611 }],
    arcHeight: 150,
    distance:  20,
    rto: { x: [5, 5], y: [0, 0] },
  },

  [SesLevel.FACTORY_WORKER]: {
    hit: [
      { x: 373, y: 467 },
      { x: 546, y: 448 },
    ],
    miss: [
      { x: 466, y: 607 },
      { x: 493, y: 520 }
    ],
    arcHeight: 150,
    distance:  20,
    rto: { x: [5, 5], y: [0, 0] },
  },

  [SesLevel.MACHINIST]: {
    hit: [{ x: 575, y: 352 }],
    miss: [
      { x: 381, y: 398 },
      { x: 700, y: 601 },
    ],
    arcHeight: 150,
    distance:  15,
    rto: { x: [5, 5], y: [0, 0] },
  },

  [SesLevel.PILOT]: {
    hit: [
      { x: 500, y: 525 },
    ],
    miss: [
      { x: 495, y: 723 },
      { x: 845, y: 417 },
    ],
    arcHeight: 150,
    distance:  16,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.DEVELOPER]: {
    hit: [{ x: 495, y: 484 }],
    miss: [
      { x: 389, y: 593 },
      { x: 626, y: 588 },
    ],
    arcHeight: 100,
    distance:  10,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.LAWYER]: {
    hit: [
      { x: 370, y: 542 },
      { x: 343, y: 552 },
      { x: 288, y: 564 },
      { x: 726, y: 564 },
      { x: 659, y: 547 },
      { x: 616, y: 537 },
    ],
    miss: [
      { x: 500, y: 589 },
      { x: 563, y: 625 },
      { x: 428, y: 657 },
    ],
    arcHeight: 120,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.SURGEON]: {
    hit: [
      { x: 524, y: 524 },
    ],
    miss: [
      { x: 400, y: 687 },
      { x: 628, y: 635 },
    ],
    arcHeight: 150,
    distance:  18,
    rto: { x: [20, 30], y: [0, 0] },
  },

  [SesLevel.GAMBLER]: {
    hit: [{ x: 508, y: 530 }],
    miss: [
      { x: 400, y: 700 },
      { x: 560, y: 650 },
    ],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.SCIENTIST]: {
    hit: [
      { x: 625, y: 500 },
      { x: 493, y: 537 },
    ],
    miss: [{ x: 637, y: 665 }],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.CORPORATE_CONSULTANT]: {
    hit: [{ x: 500, y: 414 }],
    miss: [
      { x: 686, y: 408 },
      { x: 307, y: 301 },
    ],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.BUSINESS_EXECUTIVE]: {
    hit: [{ x: 509, y: 530 }],
    miss: [{ x: 435, y: 692 }],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.NBA_PLAYER]: {
    hit: [
      { x: 500, y: 289 },
    ],
    miss: [
      { x: 670, y: 398 },
      { x: 331, y: 410 },
      { x: 377, y: 587 },
    ],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.BANKER]: {
    hit: [{ x: 478, y: 477 }],
    miss: [{ x: 490, y: 629 }],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.CEO]: {
    hit: [{ x: 500, y: 523 }],
    miss: [{ x: 418, y: 650 }],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.YACHT_OWNER]: {
    hit: [{ x: 461, y: 597 }],
    miss: [{ x: 670, y: 693 }],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

  [SesLevel.P_DIDDY]: {
    hit: [{ x: 503, y: 552 }],
    miss: [
      { x: 322, y: 344 },
      { x: 679, y: 758 },
    ],
    arcHeight: 150,
    distance:  20,
    rto: { x: [10, 10], y: [0, 0] },
  },

};

export const animateThrow = (baseThrowableEl: HTMLElement, missed: boolean, onEnd: () => void) => {

  const store    = useStore();
  const metadata = levelsMetadata[store.user.sesLevel]!;
  const {
    hit,
    miss,
    rto,
    distance,
    arcHeight,
  } = metadata;

  const target = _.sample(missed ? miss : hit)!;

  // Randomize the target position by a bit
  const rawTargetX = _.random(target.x - rto.x[0], target.x + rto.x[1]);
  const rawTargetY = _.random(target.y - rto.y[0], target.y + rto.y[1]);

  // Get the starting position of the throwable
  const startX = baseThrowableEl.getBoundingClientRect().left;
  const startY = baseThrowableEl.getBoundingClientRect().top;


  /**
   * @important
   * Due to the fact that the target coordinates
   * are based on the image size, we need to adapt
   * them based on the position of the background in
   * the viewport.
   */
  const scaleX      = window.innerWidth  / defaultImageSize.w;
  const scaleY      = window.innerHeight / defaultImageSize.h;
  const scaleFactor = Math.max(scaleX, scaleY);
  const offsetX     = (window.innerWidth  - (defaultImageSize.w * scaleFactor)) / 2;
  const offsetY     = (window.innerHeight - (defaultImageSize.h * scaleFactor)) / 2;
  const targetX     = (rawTargetX * scaleFactor) + offsetX;
  const targetY     = (rawTargetY * scaleFactor) + offsetY;


  const deltaX = targetX - startX - baseThrowableEl.offsetWidth / 2;
  const deltaY = targetY - startY - baseThrowableEl.offsetHeight / 2;


  const tiltAngle = _.random(-10, 10);


  // Create the throwable image
  const throwable = document.createElement("img");
  throwable.classList.add("w-20", "h-auto", "fixed");
  throwable.style.top  = `${startY}px`;
  throwable.style.left = `${startX}px`;
  throwable.style.zIndex = "60";
  throwable.src = getThrowableImagePath(store.user.sesLevel);



  const animate = (progress: number) => {

    const currentX = startX + deltaX * progress;
    const currentY = startY + deltaY * progress - arcHeight * Math.sin(Math.PI * progress);
    const scale    = Math.max(0.1, 1 - (progress * distance / 30));

    throwable.style.left = `${currentX}px`;
    throwable.style.top  = `${currentY}px`;
    throwable.style.transform = `scale(${scale}) rotate(${tiltAngle}deg)`;
  };


  // Add the throwable to the DOM
  document.querySelector("body")?.appendChild(throwable);


  let progress   = 0;
  const duration = 1000; // in milliseconds

  const step = () => {

    progress += 16 / duration; // Assuming 60fps
    if (progress < 1) {
      animate(progress);
      requestAnimationFrame(step);
    } else {
      onEnd();
      document.querySelector("body")?.removeChild(throwable);
      animate(1);
    }
  };

  requestAnimationFrame(step);

};


export const showFloatingElement = (
  tapEvent: PointerEvent,
  html: string,
  offsetX: number,
  offsetY: number,
  className: string,
) => {

  const body          = document.querySelector("body")!;
  const element       = document.createElement("div");
  element.innerHTML   = html;
  element.style.left  = `${tapEvent.clientX + offsetX}px`;
  element.style.top   = `${tapEvent.clientY + offsetY}px`;

  element.classList.add(className);
  body.appendChild(element);
  element.addEventListener("animationend", () => {
    try { body.removeChild(element); } catch (err) {}
  });
};


export const showTappingIncome = (tapEvent: PointerEvent, text: number | string, streak: number | boolean) => {

  // Slightly randomize the position of the streak text
  const streakXOffset = _.random(-30, 30);
  const streakYOffset = _.random(-10, 10);
  const streakHtml = `
    <div
      class="flex items-center text-grow-animation mb-4 text-xl relative text-special-green"
      style="-webkit-text-stroke: 0.5px black; left: ${streakXOffset}px; top: ${streakYOffset}px;">
      <span class="">x</span>
      <span class="">${streak}</span>
    </div>`;

  const html = `
    <div class="relative flex flex-col justify-center items-center">
      ${streak ? streakHtml : ""}
      <span class="">${text}</span>
    </div>`;

  showFloatingElement(
    tapEvent,
    html,
    0,
    -50,
    "js-game-floating-text",
  );
};


/**
 * Update the balance of the user on the server side.
 * @param balanceUpdates
 * @param cb
 */
export const updateBalance = _.debounce(async (balanceUpdates: [number, number, number][], cb: () => void) => {

  const store = useStore();
  if (!store.user) throw new UndefinedUserError();

  try {
    cb();
    Api.syncBalance(balanceUpdates);
  } catch (err) {
    console.error(err);
    Sentry.captureException(err, {
      contexts: { user: store.user }
    });
  }

}, DEBOUNCE_DELAY, {
  maxWait: 10000,
});

/**
 * Update the balance of the user locally.
 * @note This is different from the server side balance update.
 * @returns
 */
export const getTapIncome = () => {

  const store = useStore();

  if (!store.user) throw new UndefinedUserError();
  if (store.user.pileSize < 1) {
    Sentry.captureException(new UnexpectedEmptyPileSizeError(), {
      contexts: { user: store.user }
    });
    return null;
  }

  // Get the income per tap based on SES level perk & perk level.

  const baseIncomePerTap     = getIncomePerTap(store.user);
  const incomeMultiplierPerk = getPerk(store.user, "INCOME_MULTIPLIER");
  const incomeMultiplier     = incomeMultiplierPerk.effects().incomeMultiplier;
  return parseFloat((baseIncomePerTap * incomeMultiplier).toFixed(2));
};



export const startPileRegeneration = () => {

  const store = useStore();

  if (!store.user) throw new UndefinedUserError();


  return setInterval(async () => {

    const maxPileSize      = getPerk(store.user, "PILE_SIZE").effects().maxPileSize;
    const regenerationRate = getPerk(store.user, "PILE_REGEN").effects().regenRate;

    // If the pile is full, skip the regeneration call
    if (store.user.pileSize === maxPileSize) return;

    store.user.pileSize = Math.min(store.user.pileSize + regenerationRate, maxPileSize);

    try {
      await Api.syncPile(1);
    } catch (err) {
      console.error(err);
      Sentry.captureException(err, {
        contexts: { user: store.user }
      });
    }

  }, 1000);

};


/**
 * For some reason telegram is not executing
 * setInterval when the tab is minimised. This
 * will make sure that the pile is regenerated
 * even when the tab is minimised (if the user regains focus)
 */
export const handleMinimisedPileRegeneration = () => {

  const store = useStore();
  let blurTime: Date | null = null;

  const onBlur = () => {
    blurTime = new Date();

    /**
     * @important
     * Stop the pile regeneration interval so it doesnt
     * interfere with the calculations when we regain focus.
     */
    if (store.pileRegenInterval) {
      clearInterval(store.pileRegenInterval);
      store.pileRegenInterval = null
    }
  };

  // When the app regain focus
  const onFocus = () => {
    const maxPileSize     = getPerk(store.user, "PILE_SIZE").effects().maxPileSize;
    const pileRegenSpeed  = getPerk(store.user, "PILE_REGEN").effects().regenRate;
    const catchupDuration = dayjs().diff(blurTime ?? Date.now(), "seconds");
    store.user.pileSize   = Math.min(store.user.pileSize + catchupDuration * pileRegenSpeed, maxPileSize);

    if (catchupDuration < 1) return;

    Api.syncPile(catchupDuration);

    // If the pile still need to be regenerated, restart the interval
    if (store.user.pileSize < maxPileSize) {
      store.pileRegenInterval = startPileRegeneration();
    }

    blurTime = null;
  };

  document.addEventListener("visibilitychange", () => {
    document.hidden ? onBlur() : onFocus();
  });

};
