import * as Sentry from '@sentry/vue';


export default defineNuxtPlugin((nuxtApp) => {

  const config = useRuntimeConfig();
  const router = useRouter();

  if (!['dev', 'prod'].includes(config.public.stage)) return;

  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: config.public.sentryDsn,
    environment: config.public.stage,
    release: config.public.sentryRelease,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: ['prod', 'dev'].includes(config.public.stage) ? 0.2 : 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  return { provide: { sentry: Sentry } };
});
