import { User } from "@moneyshot/db/tables";
import { SesLevel } from "@moneyshot/globals/vars";


/**
 * IMPORTANT!!!
 *
 * THIS PIECE OF CODE MUST NOT CONTAIN ANY CODE OR IMPORTS
 * THAT WOULD NOT RUN ON THE FRONTEND OR THAT WOULD EXPOSE
 * SENSITIVE INFORMATION.
 */

export const MAX_SES_LEVEL = Object.keys(SesLevel).length / 2;


export const getUpgradeSesCost = (user: User) => {
  const gf = 1.35;
  return Math.round(300 * Math.pow(gf, user.sesLevel - 1));
};


/**
 * Return the income per tap based on the user
 * SES level.
 * @param user
 * @returns
 */
export const getIncomePerTap = (user: User) => {
  const gf = 1.125;
  return parseFloat((1 * Math.pow(gf, user.sesLevel - 1)).toFixed(2));
};
