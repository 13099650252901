import revive_payload_client_YTGlmQWWT5 from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/tmp/seed/source/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/tmp/seed/source/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/tmp/seed/source/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_Ib6KnAhqrq from "/tmp/seed/source/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/tmp/seed/source/modules/www/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/tmp/seed/source/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_wkOQxQamNM from "/tmp/seed/source/node_modules/@nuxtjs/plausible/dist/runtime/plugin.client.js";
import sentry_client_shVUlIjFLk from "/tmp/seed/source/modules/www/plugins/sentry.client.ts";
import plugin_auto_pageviews_client_GsPKcwd86m from "/tmp/seed/source/node_modules/@nuxtjs/plausible/dist/runtime/plugin-auto-pageviews.client.js";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  plugin_vue3_Ib6KnAhqrq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_client_wkOQxQamNM,
  sentry_client_shVUlIjFLk,
  plugin_auto_pageviews_client_GsPKcwd86m
]