import type { User } from "@moneyshot/db/tables";
import { SesLevel } from "@moneyshot/globals/vars";
import { defineStore } from "pinia";
import confetti from "canvas-confetti";

interface State {
  loading: boolean;
  user: User;
  pileRegenInterval: NodeJS.Timeout | null;
}

interface ConfettiOptions {
  particleCount?: number;
  angle?: number;
  spread?: number;
  origin?: { x: number; y: number };
  colors?: string[];
  [key: string]: any;
}

export const useStore = defineStore("store", {
  state: (): State => ({
    loading: true,
    // @ts-ignore
    user: null,
    pileRegenInterval: null,
    jsConfetti: null,
  }),

  getters: {
    levelBgImage: (state) => `/images/levels/${state.user.sesLevel}.png`,
    levelName(state) {
      return SesLevel[state.user.sesLevel]?.replaceAll("_", " ");
    },
    nextLevelName(state) {
      return SesLevel[state.user.sesLevel + 1]?.replaceAll("_", " ");
    },
    sesLevelIndex(state) {
      return SesLevel[SesLevel[state.user.sesLevel] as unknown as number];
    },
    sesLevelCount: () =>
      Object.values(SesLevel).filter((v) => typeof v === "number").length,
  },

  actions: {
    throwConfetti(options?: ConfettiOptions) {
      if (!import.meta.client) {
        return;
      }

      confetti(options);
    },

    throwFireworkConfetti() {
      if (!import.meta.client) {
        return;
      }

      const duration = 2 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = {
        startVelocity: 15,
        spread: 360,
        ticks: 80,
        zIndex: 0,
        colors: ["#75A067", "#DDFFD2", "#CCFFBA", "#FFFFFF"],
      };

      const randomInRange = (min: number, max: number) => {
        return Math.random() * (max - min) + min;
      };

      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          clearInterval(interval);
          return;
        }

        const particleCount = 50 * (timeLeft / duration);

        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
        });

        confetti({
          ...defaults,
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
        });
      }, 250);
    },

    throwStandardConfetti() {
      if (!import.meta.client) {
        return;
      }

      const count = 200;
      const defaults = {
        origin: { y: 1 },
        colors: ["#75A067", "#DDFFD2", "#CCFFBA", "#FFFFFF"],
      };

      const fire = (particleRatio: number, opts: confetti.Options) => {
        confetti({
          ...defaults,
          ...opts,
          particleCount: Math.floor(count * particleRatio),
        });
      };

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      });
      fire(0.2, {
        spread: 60,
      });
      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      });
      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      });
    },
  },
});
