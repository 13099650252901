import type { User } from "@moneyshot/db/tables";


export class CantPurchasePerkError extends Error {
  constructor(user: User, perkId: string, reason: string) {
    super(`User ${user.id} can't purchase perk ${perkId}: "${reason}"`);
    this.name = "CantPurchasePerkError";
  }
}


export class MissingUserContextError extends Error {
  constructor(perkId: string, getter: string) {
    super(`Missing required user context for perk ${perkId} for getter ${getter}`);
    this.name = "MissingUserContextError";
  }
}


export class UndefinedUserError extends Error {
  constructor() {
    super(`User is undefined`);
    this.name = "UndefinedUserError";
  }
}

export class UnknownSesLevelError extends Error {
  constructor(user: User) {
    super(`Unknown SES level for user: ${JSON.stringify(user, null, 2)}`);
    this.name = "UnknownSesLevelError";
  }
}

export class UnexpectedEmptyPileSizeError extends Error {
  constructor() {
    super(`Unexpected empty pile size`);
    this.name = "UnexpectedEmptyPileSizeError";
  }
}


export class ClientVsServerBalanceMismatchError extends Error {
  constructor(user: User, clientBalance: number) {
    super(`Client balance ${clientBalance} does not match server balance ${user.balance}`);
    this.name = "ClientVsServerBalanceMismatchError";
  }
}
