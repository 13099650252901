<script lang="ts" setup>
import { TELEGRAM_LINK, DISCORD_LINK, TWITTER_LINK } from "@moneyshot/globals/vars";
import QRCode from "qrcode";


onMounted(() => {
  const canvas = document.getElementById("qrcode") as HTMLCanvasElement;
  QRCode.toCanvas(
    canvas,
    TELEGRAM_LINK,
    {
      width: 250,
    }
  );

});
</script>

<template>

  <div class="flex flex-col flex-direction justify-start items-center h-screen px-10 pt-20 bg-image size-full">

    <p
      style="-webkit-text-stroke: 2px black;"
      class="
        capitalize font-display text-5xl text-special-green text-center
        custom-shadow-large mb-4 leading-10">
        Phones Only
    </p>

    <p
      class="text-center capitalize text-white text-xl custom-shadow-small">
      Money shot can only be played on your phone
    </p>

    <canvas id="qrcode" class="rounded-3xl p-4 m-2" />

    <FollowUs class="absolute bottom-10" />

  </div>

</template>

<style scoped>

.title {
  -webkit-text-stroke: 4px black;
}

.social-buttons {
  @apply flex justify-center items-center size-[48px] rounded-full bg-black;
}

.bg-image {
  background-image: url('/images/bg-dollars-topscreen.png');
  @apply bg-cover bg-bottom;
}

.custom-shadow-large {
  color: #9EFFBA;
  text-shadow:
  3px 3px 0 #000,
  -3px 3px 0 #000,
  -3px -3px 0 #000,
  3px -3px 0 #000;
}

.custom-shadow-small {
  text-shadow:
  1.5px 1.5px 0 #000,
  -1.5px 1.5px 0 #000,
  -1.5px -1.5px 0 #000,
  1.5px -1.5px 0 #000;
}
</style>
