import { User } from "@moneyshot/db/tables";
import { CantPurchasePerkError, MissingUserContextError } from "@moneyshot/globals/errors";
import { INCOME_MULTIPLIER_COST_GF, INCOME_MULTIPLIER_EFFECT_GF, PILE_REGEN_COST_GF, PILE_REGEN_EFFECT_GF, PILE_SIZE_COST_GF, PILE_SIZE_EFFECT_GF, SesLevel } from "@moneyshot/globals/vars";
import _ from "lodash";

/**
 * IMPORTANT!!!
 *
 * THIS PIECE OF CODE MUST NOT CONTAIN ANY CODE OR IMPORTS
 * THAT WOULD NOT RUN ON THE FRONTEND OR THAT WOULD EXPOSE
 * SENSITIVE INFORMATION.
 */

// List of the perk ids
export type PerkId = (typeof PERK_IDS)[number];
export const PERK_IDS = [
  "ACCURACY",
  "INCOME_MULTIPLIER",
  "PILE_REGEN",
  "PILE_SIZE",
  // "EMPLOYEES",
  // "ADVERTISEMENT",
] as const;

// Helper function for props that can be functions
type Getter<T> = (user?: User) => T;

/**
 * This is used so we can type the effects of each perk
 * depending on the perk id.
 */
type PerkEffectMap = {
  MULTITAP: { canMultiTap: boolean };
  ACCURACY: { missRate: number };
  INCOME_MULTIPLIER: { incomeMultiplier: number };
  PILE_REGEN: { regenRate: number };
  PILE_SIZE: { maxPileSize: number };
  EMPLOYEES: {};
  ADVERTISEMENT: {};
};

type PerkEffect<K extends PerkId> = PerkEffectMap[K];

export interface Perk<T extends PerkId> {

  state: Record<string, any> & ({ perkLevel: number } | { enabled: boolean });

  readonly minSesLevel: () => SesLevel;
  readonly maxSesLevel: () => SesLevel;
  readonly upgradeCost: Getter<number>;
  readonly imgUrl: Getter<string>;
  readonly title: Getter<string>;
  readonly shortDescription: Getter<string>;
  readonly description: Getter<string>;
  readonly effects: (user?: User) => PerkEffect<T>;
  readonly afterPurchase: (user: User) => User;
  readonly totalMaxPerkLevel: Getter<number>;
  readonly maxPerkLevel: Getter<number>;
  readonly usageLeftBeforeCooldown: Getter<number>;
  readonly cooldownPeriod: Getter<number>;
  readonly expireIn: Getter<Date>;
  readonly reachedMaxLevel: (user?: User) => boolean;
}

/**
 * Factory function to create a perk with additional
 * benefits like default values setting.
 * @param args
 * @returns
 */
const perkFactory = <T extends PerkId>(
  args: {
    title: Getter<string>;
    description: Getter<string>;
    shortDescription?: Getter<string>;
    imgUrl: Getter<string>;
    state: Perk<T>["state"];
    upgradeCost: Perk<T>["upgradeCost"];
    effects: Perk<T>["effects"];
  } & Partial<Perk<T>>
): Perk<T> => ({
  title: (args.title ?? (() => "")),
  description: (args.description ?? (() => "")),
  shortDescription: (args.shortDescription ?? (() => "")),
  imgUrl: args.imgUrl,
  state: args.state,
  afterPurchase: args.afterPurchase ?? ((user) => user),
  upgradeCost: args.upgradeCost,
  minSesLevel: () => SesLevel.HOMELESS,
  maxSesLevel: () => Infinity,
  effects: args.effects,
  totalMaxPerkLevel: args.totalMaxPerkLevel ?? (() => -Infinity),
  maxPerkLevel: args.maxPerkLevel ?? args.totalMaxPerkLevel ?? (() => -Infinity),
  usageLeftBeforeCooldown: args.usageLeftBeforeCooldown ?? (() => Infinity),
  cooldownPeriod: args.cooldownPeriod ?? (() => 0),
  expireIn: args.expireIn ?? (() => new Date("9999-12-31")), // Set a very far off date
  reachedMaxLevel(user) { return this.state.perkLevel === this.maxPerkLevel(user); }
});

const BASE_MISS_RATE = 30; // 30% miss rate
const BASE_PILE_SIZE = 300;



const toPercentage = (value: number) => 1 + (value / 100);
const growthFactor = (start: number, end: number, levels: number) => Math.pow(end / start, 1 / (levels - 1));
const exponential  = (start: number, gf: number, level: number) => Math.round(start * Math.pow(gf, level - 1));
const tierUnlock   = (user: User, args: { step: number, factor: number, start: number }) => {
  return args.start + (Math.floor(user.sesLevel / args.step) * args.factor);
};



export const PERKS: { [K in PerkId]: Perk<K> } = {

  ACCURACY: perkFactory({

    state: { perkLevel: 1 },

    totalMaxPerkLevel() { return 10; },
    upgradeCost(_) {
      const gf = growthFactor(500, 500_000, this.totalMaxPerkLevel!());
      return exponential(500, gf, this.state.perkLevel);
    },


    title:            () => "Accuracy",
    shortDescription: () => "Decrease your chance of missing",
    imgUrl:           () => "images/perks/accuracy",
    description() {

      const currMissRate = this.effects().missRate as number;
      const totalLevels  = this.maxPerkLevel!();
      const delta        = (BASE_MISS_RATE / totalLevels);
      const baseMessage  = "Decrease the chance of your throws missing the target";

      return this.reachedMaxLevel!() ?
        baseMessage :
        `${baseMessage} · (**${currMissRate}%** → **${currMissRate - delta}%**)`;
    },


    effects() {
      /**
       * @note
       * We decrease the miss rate by @see BASE_MISS_RATE / totalLevels (ie maxLevel)
       * for each level so we can reach 0% miss rate.
       */
      // TODO: Could be cleaner
      const delta = ((BASE_MISS_RATE / 10) * (this.state.perkLevel - 1));

      return {
        missRate: (BASE_MISS_RATE - delta),
        delta,
      };
    },

  }),


  INCOME_MULTIPLIER: perkFactory({

    state: { perkLevel: 1 },

    upgradeCost(_) {
      const gf = toPercentage(INCOME_MULTIPLIER_COST_GF);
      return exponential(500, gf, this.state.perkLevel);
    },
    totalMaxPerkLevel() { return 30; },
    maxPerkLevel(user) {
      if (!user) throw new MissingUserContextError("INCOME_MULTIPLIER", "maxPerkLevel");
      return Math.min(this.totalMaxPerkLevel!(), tierUnlock(user!, { step: 5, factor: 2, start: 5 }));
    },


    title:            () => "Income Multiplier",
    shortDescription: () => "Earn more money per throw",
    imgUrl:           () => "images/perks/multiplier",
    description() {
      return `Increase the value of the items you are throwing to earn more money (+**${INCOME_MULTIPLIER_EFFECT_GF}%**)`;
    },


    effects() {
      const gf = toPercentage(INCOME_MULTIPLIER_EFFECT_GF);
      const incomeMultiplier = Math.pow(gf, this.state.perkLevel - 1);
      return { incomeMultiplier };
    },

  }),


  PILE_REGEN: perkFactory({

    state: { perkLevel: 1 },

    upgradeCost(_) {
      const gf = toPercentage(PILE_REGEN_COST_GF);
      return exponential(300, gf, this.state.perkLevel);
    },
    totalMaxPerkLevel() { return 30; },
    maxPerkLevel(user) {
      if (!user) throw new MissingUserContextError("PILE_REGEN", "maxPerkLevel");
      return Math.min(this.totalMaxPerkLevel!(), tierUnlock(user!, { step: 5, factor: 2, start: 5 }));
    },


    title:            () => "Energy Regeneration",
    shortDescription: () => "Increase the rate of energy regeneration",
    imgUrl:           () => "images/perks/winebottle",
    description() {
      return `Increase the speed at which your energy regenerates (**+${PILE_REGEN_EFFECT_GF}%**)`;
    },


    effects() {
      const gf = toPercentage(PILE_REGEN_EFFECT_GF);
      return { regenRate: (1 * Math.pow(gf, this.state.perkLevel - 1)) };
    },
  }),



  PILE_SIZE: perkFactory({

    state: { perkLevel: 1 },

    upgradeCost() {
      const gf = toPercentage(PILE_SIZE_COST_GF);
      return exponential(300, gf, this.state.perkLevel);
    },
    totalMaxPerkLevel() { return 30; },
    maxPerkLevel(user) {
      if (!user) throw new MissingUserContextError("PILE_SIZE", "maxPerkLevel");
      return Math.min(this.totalMaxPerkLevel!(), tierUnlock(user!, { step: 5, factor: 2, start: 5 }));
    },


    title:            () => "Energy Level",
    shortDescription: () => "Increase your maximum energy level",
    imgUrl:           () => "images/perks/energytank",
    description(user) {

      if (!user) throw new MissingUserContextError("PILE_SIZE", "description");

      const currentPileSize = this.effects().maxPileSize as number;
      const baseMessage     = "Increase your maximum energy level to throw more items for longer";

      return this.reachedMaxLevel!(user) ?
        baseMessage :
        `${baseMessage} · (**${currentPileSize}** → **${Math.round(currentPileSize * toPercentage(PILE_SIZE_EFFECT_GF))}**)`;
    },


    effects() {
      const gf = toPercentage(PILE_SIZE_EFFECT_GF);
      const maxPileSize = exponential(BASE_PILE_SIZE, gf, this.state.perkLevel);
      return { maxPileSize };
    },

    afterPurchase(user) {
      /**
       * @note
       * After purchasing the perk update,
       * we refill the user's pile size to the maximum.
       */
      user.pileSize = this.effects!().maxPileSize;
      return user;
    },
  }),


};





/**
 * Whether the given user can purchase a perk.
 * It will return a flag along with a message explaining
 * why the user can't purchase the perk.
 * @param perkId
 * @returns
 */
export const canPurchasePerk = <T extends PerkId>(user: User, perkId: T) => {
  const perk = getPerk(user, perkId);

  const message = (() => {
    if ("enabled" in perk.state && perk.state.enabled)
      return "Already purchased";
    if (perk.state.perkLevel === perk.maxPerkLevel(user))
      return "Max level reached";
    // if (user.balance < perk.upgradeCost(user)) return `Insufficient balance ${user.balance.toFixed(2)} < ${perk.upgradeCost(user)}`;
    if (user.balance < perk.upgradeCost(user)) return `Insufficient balance`;
    if (perk.usageLeftBeforeCooldown(user) === 0) return "Perk on cooldown";
    return null;
  })();

  // TODO: Not good
  return { message, canPurchase: !message };
};



/**
 * Buy a perk and return the updated perk data and updated user data.
 * @param perkId
 * @returns
 */
export const purchasePerk = <T extends PerkId>(user: User, perkId: T) => {
  const { canPurchase, message } = canPurchasePerk(user, perkId);
  if (!canPurchase) throw new CantPurchasePerkError(user, perkId, message!);

  const perk = getPerk(user, perkId);
  const cost = perk.upgradeCost(user);

  if ("enabled" in perk.state) perk.state.enabled = true;
  else perk.state.perkLevel++;

  return { purchasedPerk: perk, cost };
};



/**
 * Return the perk data for a user (including methods).
 * @param user
 * @param perkId
 * @returns
 */
export const getPerk = <T extends PerkId>(user: User, perkId: T): Perk<T> => {
  const userPerkData = _.cloneDeep(user.perks[perkId]);
  const basePerk     = _.cloneDeep(PERKS[perkId]);
  basePerk.state     = userPerkData ? userPerkData.state : basePerk.state;
  return basePerk;
};
