
///////// REFERRAL /////////

export const INVITER_BONUS = 500;
export const INVITEE_BONUS = 250;
export const INVITER_LEVELUP_BONUS = 250;
export const INVITE_PREFIX = "invite_";


//////// STREAK BONUS /////////

// TODO:


///////// PERKS /////////

// Income Multiplier
export const INCOME_MULTIPLIER_COST_GF   = 25
export const INCOME_MULTIPLIER_EFFECT_GF = 5;

// Pile Regen
export const PILE_REGEN_COST_GF   = 25;
export const PILE_REGEN_EFFECT_GF = 5;

// Pile Regen
export const PILE_SIZE_COST_GF   = 25;
export const PILE_SIZE_EFFECT_GF = 7.5;


///////// SES LEVELS /////////
// (Ses stands for Socio-Economical Status)

export enum SesLevel {
  HOMELESS = 1,
  JANITOR = 2,
  NEWSPAPER_DELIVERY = 3,
  BURGER_FLIPPER = 4,
  SHELF_FILLER = 5,
  PLUMBER = 6,
  GARDENER = 7,
  FISHER = 8,
  EXORCIST = 9,
  CASHIER = 10,
  FARMER = 11,
  BOUNCER = 12,
  CONSTRUCTION_WORKER = 13,
  TRUCK_DRIVER = 14,
  BABY_SITTER = 15,
  WAITER = 16,
  HOUSEKEEPER = 17,
  PIZZA_MAKER = 18,
  DRUG_DEALER = 19,
  PAINTER = 20,
  PIRATE = 21,
  RECEPTIONIST = 22,
  ZOMBIE_HUNTER = 23,
  NURSE = 24,
  PORN_PRODUCER = 25,
  FACTORY_WORKER = 26,
  MACHINIST = 27,
  PILOT = 28,
  DEVELOPER = 29,
  LAWYER = 30,
  SURGEON = 31,
  GAMBLER = 32,
  SCIENTIST = 33,
  CORPORATE_CONSULTANT = 34,
  BUSINESS_EXECUTIVE = 35,
  NBA_PLAYER = 36,
  BANKER = 37,
  CEO = 38,
  YACHT_OWNER = 39,
  P_DIDDY = 40,
}


///////// LINKS /////////
export const TELEGRAM_LINK = "https://t.me/themoneyshotbot";
export const TWITTER_LINK = "https://x.com/moneyshotdotgg";
export const DISCORD_LINK = "https://discord.gg/mCmZUr5eWM";


export const REMINDER_CUTOFF_DAYS = 2;
