import { TonConnectUI } from "@tonconnect/ui";


// TODO: Make this dynamic
export const MANIFEST_URL = "https://d1mjnnqyhy605r.cloudfront.net/tonconnect-manifest.json";
const INVITE_PREFIX = "invite_";


interface InitDataUnsafe {
  auth_date: string,
  hash: string,
  query_id: string,
  chat_instance?: string,
  chat_type?: string,
  start_param?: string,
  user: {
    allows_write_to_pm: boolean,
    first_name: string,
    id: number,
    language_code: string,
    last_name: string,
    username: string,
  }
}

export const getTelegramUserId = async () => {

  const config = useRuntimeConfig();
  if (config.public.stage.startsWith("local")) {
    return Number(config.public.userId);
  }

  const telegram = window.Telegram.WebApp;
  return await new Promise<number>((resolve, reject) => {
    while (!telegram.initDataUnsafe?.user?.id) { setTimeout(() => { /* noop */ }, 100); }
    resolve(telegram.initDataUnsafe.user.id);
  });
};


export const isOnDesktop = () => {
  const telegram = window.Telegram.WebApp;
  return [
    "macos",
    "windows",
    "linux",
    "tdesktop",
  ].includes(telegram.platform);
};


export const getTelegram = () => {
  const config = useRuntimeConfig();
  if (config.public.stage.startsWith("local")) {
    return { expand() { /* noop */ } };
  } else return window.Telegram.WebApp;
};
